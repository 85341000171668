import Vue from 'vue';
import { WSController } from '~/libs/WSController.js'

export default ({ app }, inject) => {

    inject('getWSController', () => {
        
        if (app.store.state.auth.user) {
            // XXX: Find better way to get token
            let token = app.$auth.$storage._state['_token.localRefresh'].replace('Bearer', '').replace(' ', '');
            
            return new WSController(process.env.WS_DOMAIN, token, app)

        }
    })
}

