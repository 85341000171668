import Vue from 'vue'
var ColorScheme = require('color-scheme');
var scheme = new ColorScheme;
scheme.from_hex("279BE2")         // Start the scheme 
    
      .scheme('triade')     // Use the 'triade' scheme, that is, colors
      .distance(0.01)
                            // selected from 3 points equidistant around
                            // the color wheel.
      .variation('soft')   // Use the 'soft' color variation
      .web_safe(true);

var colors = scheme.colors().map(c => `#${c}`);

var michaelColor = ['#80BB45', '#1C5253', '#5A9367', '#FFB86F'];
var michaelColor = ['#88C649', '#96ff99', '#ffb86f', '#fff665', '#53f4ef'];

export const state = () => ({
	cleanerGraphs: [
    ],
    sectionGraphs: [
    ],
    sectionDefaultParams: ['g_pv_iTemperatureSectionA', 'g_pv_iTemperatureSectionB',],
    cleanerDefaultParams: ['GVL_Processvalues.rAcidSwamp_pH_PiCompPV', 'GVL_Parameters.rAcidSwamp_pH_PiCompSP', 'GVL_Processvalues.rAirDuctPressPiCompOutPut', 'GVL_Processvalues.rAirDuctPressPiCompPV',  ],
    manureDefaultParams: ['GVL_Processvalues.rAcidSwamp_pH_PiCompPV', 'GVL_Parameters.rAcidSwamp_pH_PiCompSP', 'GVL_Processvalues.rAirDuctPressPiCompOutPut', 'GVL_Processvalues.rAirDuctPressPiCompPV',  ],
    cleanerSpecialDefaultParams: ['GVL_Parameters.rAirDuctPressPiCompSP', 'GVL_Processvalues.rAirDuctPressPiCompPV', 'GVL_Processvalues.rAirDuctPressPiCompOutPut',  ],
    cleanerDefaultColors: [...michaelColor, ...colors.reverse()], //['red', 'purple', 'blue', 'yellow', 'pink', 'orange', 'white' ]
    sectionDefaultColors: [...michaelColor, ...colors.reverse()]
})

export const mutations = {
	setCleanerGraphs(state, cleanerGraphs) {
        state.cleanerGraphs = cleanerGraphs
        Vue.set(state,'cleanerGraphs', cleanerGraphs)
    },
    changeParams(state, options) {
        try {
            state.cleanerGraphs[options.idx].paramName = options.paramName    
            state.cleanerGraphs[options.idx].keypretty = options.keypretty    
        } catch (error) {
            alert(options.idx)
        }  
    },

    setSectionGraphs(state, sectionGraphs) {
        state.sectionGraphs = sectionGraphs
        Vue.set(state,'sectionGraphs', sectionGraphs)
    },

    changeSectionParams(state, options) {
        try {
            state.sectionGraphs[options.idx].paramName = options.paramName    
            state.sectionGraphs[options.idx].keypretty = options.keypretty    
        } catch (error) {
            alert(options.idx)
        }  
    }

}

export const actions = {

    async updateUserSettings({commit}, data) {
        let newObj = Object.assign(data, {})
        if (!data.settings) {
            newObj.settings  = {
                sectionDefaultParams: ['g_pv_iTemperatureSectionA', 'g_pv_iTemperatureSectionB',],
                cleanerDefaultParams: ['GVL_Processvalues.rAcidSwamp_pH_PiCompPV', 'GVL_Parameters.rAcidSwamp_pH_PiCompSP', 'GVL_Processvalues.rAirDuctPressPiCompOutPut', 'GVL_Processvalues.rAirDuctPressPiCompPV',  ],
                manureDefaultParams: ['GVL_Processvalues.rAcidSwamp_pH_PiCompPV', 'GVL_Parameters.rAcidSwamp_pH_PiCompSP', 'GVL_Processvalues.rAirDuctPressPiCompOutPut', 'GVL_Processvalues.rAirDuctPressPiCompPV',  ],
                cleanerSpecialDefaultParams: ['GVL_Parameters.rAirDuctPressPiCompSP', 'GVL_Processvalues.rAirDuctPressPiCompPV', 'GVL_Processvalues.rAirDuctPressPiCompOutPut',  ],
            }
        }
        
        let result =  await this.$axios.$post(`/api/users/${data.userId}/update_user_settings/`, data);
        console.log(result)



    }

    

	
}