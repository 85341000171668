import AdminSVG from "~/assets/menu_icons/Admin.svg";
import Dashboard from "~/assets/menu_icons/Dashboard.svg";
import List from "~/assets/menu_icons/List.svg";
import Map from "~/assets/menu_icons/Map.svg";
import Settings from "~/assets/menu_icons/Settings.svg";
import Weather from "~/assets/menu_icons/Weather.svg";
import Logout from "~/assets/menu_icons/Logout.svg";

let getAppMenuItems = function() {
  let links = [
    {
      icon: "list",
      isSvg: false,
      title: this.$t("list"),

      to: this.localePath({ name: "list" }),
      login: true
    },
    // {
    //   icon: "build",
    //   isSvg: false,
    //   title: this.$t("Settings"),

    //   to: this.localePath({ name: "settings" }),
    //   login: true
    // },

    {
      icon: Logout,
      isSvg: true,
      title: this.$t("logout"),
      to: this.localePath({ name: "login" }),
      login: true
    }
  ];

  if (this.$auth.user && this.$auth.user.is_admin) {
    links.push({
      icon: "supervisor_account",
      isSvg: false,
      title: this.$t("Full admin"),
      href: "https://api.login.agrifarm.dk/admin",
      //to: "/list",
      login: true
    });
  }

  return links;
};

let getBarnMenuItems = function() {
  let links;
  if (!this.currentStable) return [];

  if (this.currentStable.permissions.indexOf('external_employee') > -1) {
    links = [];

  } else {
    links = [
      {
        icon: "dashboard",
        isSvg: false,
        title: this.$t("overview"),
        //to: "/stable/" + this.currentStable.id + "/overview",
        to: this.localePath({
          name: "stable-id-overview",
          params: { id: this.currentStable.id }
        }),
        login: true
      }
    ];

  }
  let manureLinks = []
  this.currentStable.sections
    .filter(s => s.section_id.indexOf("manure") > -1)
    .forEach(manure => {
      manureLinks.push({
        icon: "layers",
        isSvg: false,
        title: this.$t("Central"),
        has_timeout: manure.has_timeout,
        //to: "/stable/" + this.currentStable.id + "/parameters/",
        to: this.localePath({
          name: "stable-id-manure-overview",
          params: { id: this.currentStable.id }
        }),
        login: true
      })
      if (this.currentStable.permissions.indexOf('external_employee') > -1 )
      {
        
        return 
      } else {
        manureLinks.push({
          icon: "layers",
          isSvg: false,
          title: this.$t("Renser 1"),
          has_timeout: manure.has_timeout,
          //to: "/stable/" + this.currentStable.id + "/parameters/",
          to: this.localePath({
            name: "stable-id-cleaner-cid-overview",
            params: { id: this.currentStable.id, cid: manure.section_id }
          }),
          login: true
        })
      }
    })
  

  let sectionLinks = this.currentStable.sections
    .filter(s => s.section_id.indexOf("section") > -1 || s.section_id.indexOf("centralcontrol") > -1)
    .map(section => {
      if (this.currentStable.permissions.indexOf('external_employee') > -1 && section.section_id !== 'centralcontrol' )
      {
        
        return
      }
      let data = {
        icon: "layers",
        isSvg: false,
        title: this.$t(section.name),
        has_timeout: section.has_timeout,

        //to: "/stable/" + this.currentStable.id + "/section/" + section.section_number +"/overview/",
        to: this.localePath({
          name: "stable-id-section-section-overview",
          params: { id: this.currentStable.id, section: section.section_number }
        }),

        login: true
      };
      if (section.section_id == "centralcontrol") {
        data.to = this.localePath({
          name: "stable-id-control-overview",
          params: { id: this.currentStable.id }
        });
      }
      return data;
    }).filter(e => e);

  let cleanerLinks = this.currentStable.sections
    .filter(s => s.section_id.indexOf("cleaner") > -1 || s.section_id.indexOf("cleaner") > -1)
    .map(section => {
      if (this.currentStable.permissions.indexOf('external_employee') > -1 )
      {
        
        return
      }
      
      return {
        icon: "layers",
        isSvg: false,
        title: this.$t(section.name),
        has_timeout: section.has_timeout,
        //to: "/stable/" + this.currentStable.id + "/parameters/",
        to: this.localePath({
          name: "stable-id-cleaner-cid-overview",
          params: { id: this.currentStable.id, cid: section.section_id }
        }),
        login: true
      };
    }).filter(e => e);

  let otherLinks = [];

  if (this.currentStable.permissions.indexOf("admin_permission") > -1 || this.currentStable.permissions.indexOf("advanced_permission") > -1) {
    otherLinks.push({
      icon: "settings",
      isSvg: false,
      title: this.$t("Parameters"),
      //to: "/stable/" + this.currentStable.id + "/parameters/",
      to: this.localePath({
        name: "stable-id-parameters",
        params: { id: this.currentStable.id }
      }),
      login: true
    });
  }

  let serviceLinks = [
    {
      icon: "build",

      title: this.$t("Service"),
      //to: "/stable/" + this.currentStable.id + "/service/",
      to: this.localePath({
        name: "stable-id-service",
        params: { id: this.currentStable.id }
      }),
      login: true,
      disabled: false
    }
  ];

  serviceLinks.forEach(e => {
    otherLinks.push(e);
  });

  let adminExtras = [
    {
      icon: "supervisor_account",

      title: this.$t("Admin"),
      //to: "/stable/" + this.currentStable.id + "/service/",
      to: this.localePath({
        name: "stable-id-admin",
        params: { id: this.currentStable.id }
      }),
      login: true
    }
  ];

  let lastLinks = [
    {
      icon: "list",
      isSvg: false,
      title: this.$t("list"),
      to: this.localePath({ name: "list" }),
      //to: "/list",
      login: true
    },
    {
      icon: Logout,
      isSvg: true,
      title: this.$t("logout"),
      to: "/login?logout=1",
      login: true
    }
  ];

  if (this.currentStable.permissions.indexOf("admin_permission") > -1 || this.currentStable.permissions.indexOf("advanced_permission") > -1) {
    adminExtras.forEach(e => {
      otherLinks.push(e);
    });
  }

  lastLinks.forEach(e => {
    otherLinks.push(e);
  });

  if (this.$auth.user && this.$auth.user.is_admin) {
    otherLinks.push({
      icon: "supervisor_account",
      isSvg: false,
      title: this.$t("Full admin"),
      href: "https://api.login.agrifarm.dk/admin",
      //to: "/list",
      login: true
    });
  }

  return links
    .concat(manureLinks)
    .concat(sectionLinks)
    .concat(cleanerLinks)
    .concat(otherLinks);
};
export { getAppMenuItems, getBarnMenuItems };
