import Vue from "vue";
import ConfirmDialog from "~/components/ConfirmDialog.vue";
import PDFDialog from "~/components/PDFDialog.vue";
import JsonViewer from "vue-json-viewer";
import moment from "moment-timezone";
import TimePicker from "~/components/TimePicker.vue";
import DateComponent from "~/components/DateComponentV2.vue";
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";

Vue.component("DateComponent", DateComponent)
Vue.component("TimePicker", TimePicker)

Vue.use(JsonViewer);



Vue.component("ScaleLoader", ScaleLoader);

Vue.component("confirm-dialog", ConfirmDialog);

Vue.component("pdf-dialog", PDFDialog)


Vue.filter("yesno", function(value) {
  if (value === undefined || value === null) return "N/A";
  return value === true ? "Yes" : "No";
});

Vue.filter("maxLength", function(value, maxLength, shouldEnforce) {
  
  try {
    if (value.length > maxLength && shouldEnforce) {
      return value.substring(0, maxLength - 4) + " ..."
    }
    
  } catch (error) {
    console.error(error)
  }
  return value
})

Vue.filter("oneDecimal", function(value, unit) {
  let filterUnits = ['ppm', 'm3/h']
  try {
    if (typeof value === "number") {
      if (filterUnits.indexOf(unit) === -1 ) {
        return parseFloat(value).toFixed(1);
      }
      
    }
  } catch (error) {}

  return value;
});

Vue.filter("tempNumber", function(value) {
  if (typeof value === "number") {
    return parseFloat(value).toFixed(1);
  }
  return value;
});

Vue.filter("prettyEventType", (event) => {
  switch (event) {
    case "control":
      return "Section control";
    case "parameter":
      return "Parameter";
    case "connection_established":
      return "Connection established";
    case "timeout":
      return "Timeout";
    case "acknowledgement":
      return "Acknowledgement";
    case "alarm":
      return "Alarm";
    default:
      return event;
  }
});

Vue.filter("item_ack", (items) => {
  let ackParam = items.find((item) => {
    if (item.keyName.indexOf(".3") > -1 || item.keyName.indexOf(".4") > -1) {
      return item;
    }
  });
  if (ackParam) {
    return ackParam.value;
  }
});

Vue.filter("moment", (data, timezone) => {
  let date = moment(data * 1000);
  
  if (timezone ) {
    return date.tz(timezone).format("DD/MM/YYYY HH:mm")
  }
  return date.format("DD/MM/YYYY HH:mm")
});

const ALL_DAYS = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];
const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const dayNumberToString = (dayNumber) => {
  return ALL_DAYS[dayNumber];
};

Vue.filter("eventAlarm", function(data, stable) {
  let section = stable.sections.find((s) => s.section_id == data.section_id);

  if (section) {
    return section.schema.keys[data.acknowledgement_parameter].keypretty;
  }
  return data;
});

Vue.filter("schemaKeyPretty", function(keyName, stable, section_id) {
  let section = stable.sections.find((s) => s.section_id == section_id);
  if (section) {
    return section.schema.keys[keyName] ? section.schema.keys[keyName].keypretty : keyName;
  }
  return section_id;
});

Vue.filter("acknowledgement", function(data, stable, section_id, translate) {
  let section = stable.sections.find((s) => s.section_id == section_id);

  if (section) {
    let param = section.schema.keys[data];
    if (param) {
      return translate(param.keypretty);
    }
  }
  return data;
});

Vue.filter("masterSlaveFilter", (item, translate) => {
  if (item.keyName == "GVL_Processvalues.xFan1and2Master" || item.keyName == "GVL_Processvalues.stProc.xFan1and2Master") {
    return item.a.value ? "Master" : "Slave";
  }
  let on = translate("On");
  return item.a.value ? on : "Off";
});

Vue.filter("eventFilter", function(data, stable, translate, filters) {
  let to = translate("to");
  if (!data) return;

  try {
    if (data.event_type === "alarm") {
      if (data.alarm == null) {
        return data.description + " - " + translate("missing alarm definition")
      }
      let text = `${filters.schemaKeyPretty(
        data.alarm.parameter,
        stable,
        data.section_id
      )}`;
      if (text.includes("acknowledgement_parameter")) {
        return data.description + " - " + translate("missing alarm definition")
      }
      text = translate(text);
      if (data.alarm.cleared_at) {
        text =
          text +
          translate(" - Alarm cleared at ") +
          moment(data.alarm.cleared_at * 1000).format("DD/MM/YYYY HH:mm:ss");
      }
      return text;
    }

    if (["connection_established", "timeout"].indexOf(data.event_type) > -1) {
      return;
    }

    if (data.description) {
      return `${data.description} - ${translate(
        data.acknowledgement_parameter
      )}`;
    }
    if (data.command.sectionControl) {
      let section = stable.sections.find(
        (s) => s.section_id == data.command.plc
      );

      let prettyName = translate(
        section.schema.state.mode_section[data.command.value].result
      );
      let oldPrettyName;

      if (data.command.lastValue !== undefined) {
        oldPrettyName = translate(
          section.schema.state.mode_section[data.command.lastValue].result
        );
      }
      let startText = translate("Section control switched from");

      return `${startText} <span class="old">${oldPrettyName}</span> ${to} <span class="new">${prettyName}</span>`;
    } else if (data.command.slurryControl) {
      let startText = translate("Changed from");

      let to = translate("to");
      let lastDay = capitalize(
        translate(dayNumberToString(data.command.lastValue.day))
      );
      let currentDay = capitalize(
        translate(dayNumberToString(data.command.day))
      );
      return `${startText} <span class="old parameter">${
        data.command.lastValue.start
      } - ${
        data.command.lastValue.end
      } ${lastDay}</span> ${to} <span class="new parameter">${
        data.command.start
      } - ${data.command.end} ${currentDay}</span>`;
    } else {
      let startText = translate("Parameter");
      to = translate("set to");
      return `${startText} <span class="parameter-name">${translate(
        data.command.parameter.name
      )}</span> ${to} <span class="new parameter">${data.command.value}</span>`;
    }
  } catch (error) {
    console.log(error);
  }

  return data;
});

//Vue.use(VueChatScroll);
