<template>
  <div id="pdf-view"></div>
</template>


<script>
//const ADOBE_KEY = 'c78791efd6a045aba34cdd23b30a2943';
const ADOBE_KEY = '734604edfbe54a4d85a3ff9eca1756ab';
import EventBus from "~/libs/EventBus.js";
const util = require('util')
export default {
  props: ["pdf"],
  data() {
    return {
      dialog: false,
      adobeDCView:null,
      options: {
        color: "primary",
        zIndex: 200,
      },
    };
  },
  mounted() {
    //credit: https://community.adobe.com/t5/document-services-apis/adobe-dc-view-sdk-ready/m-p/11648022#M948
    this.$nextTick(() => {
      let pdfviewer = document.createElement('script');    
      pdfviewer.setAttribute('src',"https://documentcloud.adobe.com/view-sdk/main.js");
      document.head.appendChild(pdfviewer);
    })
    this.$nextTick(() => {
      console.log(window.AdobeDC)
      EventBus.$on('pdfshow', this.previewPDF)
    })
  },
  destroyed() {
    document.body.classList = null
  }, 
  methods: {
    previewPDF(callback) {
      this.adobeDCView = new AdobeDC.View({
        clientId: ADOBE_KEY, 
        divId: "pdf-view"
      });
      
      this.adobeDCView.registerCallback(
        /* Type of call back */
        AdobeDC.View.Enum.CallbackType.EVENT_LISTENER,
        /* call back function */
        (event) => {
            switch(event.type) {
              case "PDF_VIEWER_CLOSE":
              break;
            }
        },{
            enablePDFAnalytics: true,
        }
      );
      let viewer = this.adobeDCView;
      viewer.previewFile({
          content: { promise: callback.response }, 
          metaData: { fileName: callback.fileName }
        }, {embedMode: "LIGHT_BOX"});
    }
  },
};

// In theory I'm not needed on CodePen, but in the real world I would be.



// adobeDCView.previewFile({
//   content: {
//     location: {url: "https://documentcloud.adobe.com/view-sdk-demo/PDFs/Bodea Brochure.pdf"}},
//     metaData: {fileName: "Bodea Brochure.pdf"}
// });


</script>

<style>

</style>